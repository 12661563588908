<template>
    <div class="wrapper_main" style="min-height: 100dvh;">
        <main class="content">
            <div class="headline">
                <h1 class="headline__title">{{ $translate('create-a-dispatch') }}</h1>
                <p class="headline__text">{{ $translate('here-you-can-track-all-your-shipments-of-products-to-other-users') }}</p>
            </div>
            <div class="tabs-wrapper">  
                <ul class="tabs">
                    <li class="tabs__item">
                        <a
                            data-tab="info" class="nav-tab" 
                            @click="changeTab('info')" :class="isActiveTab('info')" > {{ $translate('basic-info') }}
                        </a>
                    </li>
                    <li class="tabs__item tutorial_tooltip_2 tutorial_tooltip_fs13 tutorial_tooltip_bottom_center">
                        <a
                            data-tab="companies" class="nav-tab"
                            @click="changeTab('companies')" :class="isActiveTab('companies')"> {{ $translate('select-companies') }}
                        </a>
                    </li>
                    <li class="tabs__item tutorial_tooltip_3 tutorial_tooltip_fs13 tutorial_tooltip_bottom_center">
                        <a
                            data-tab="products" class="nav-tab" 
                            @click="changeTab('products')" :class="isActiveTab('products')"> {{ $translate('products') }} 
                        </a>
                    </li>
                    <li class="tabs__item tutorial_tooltip_4 tutorial_tooltip_fs13 tutorial_tooltip_bottom_center">
                        <a
                            data-tab="documents" class="nav-tab"
                            @click="changeTab('conditions')" :class="isActiveTab('conditions')"> {{ $translate('contract-conditions') }}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="content__body">
                <div class="tab-content">
                    <div id="info" class="tab-content__item" :class="isActiveTab('info')">
                        <div class="wizard-item tutorial_tooltip_1 tutorial_tooltip_right_center">
                            <div class="items-group cards">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title" >{{ $translate('dispatch-id') }}*</label>
                                        <input type="text" class="input" placeholder="123456789" v-model="dispatchID"/>
                                    </div>
                                </div>
                            </div>
                            <hr class="line-hr my-25" />
                        </div>
                        <div class="headline-wrapper flex items-center">
                            <div class="headline headline--sm flex-auto">
                                <h2 class="headline__title">{{ $translate('dispatch-documents') }}</h2>
                                <p class="headline__text">{{ $translate('list-of-documents-attached-to-despatch') }}</p>
                            </div>
                        </div>
                        <ul class="editable-list">
                            <li class="editable-list__item" v-for="d in documents" :key="d.name">
                                <div class="data-row">
                                    <ul class="data-row__list">
                                        <li class="data-row__item">
                                            <span class="data-row__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" v-if="d.file.type.includes('image')">
                                                    <path d="M14.2639 15.9375L12.5958 14.2834C11.7909 13.4851 11.3884 13.086 10.9266 12.9401C10.5204 12.8118 10.0838 12.8165 9.68048 12.9536C9.22188 13.1095 8.82814 13.5172 8.04068 14.3326L4.04409 18.2801M14.2639 15.9375L14.6053 15.599C15.4112 14.7998 15.8141 14.4002 16.2765 14.2543C16.6831 14.126 17.12 14.1311 17.5236 14.2687C17.9824 14.4251 18.3761 14.8339 19.1634 15.6514L20 16.4934M14.2639 15.9375L18.275 19.9565M18.275 19.9565C17.9176 20 17.4543 20 16.8 20H7.2C6.07989 20 5.51984 20 5.09202 19.782C4.71569 19.5903 4.40973 19.2843 4.21799 18.908C4.12796 18.7313 4.07512 18.5321 4.04409 18.2801M18.275 19.9565C18.5293 19.9256 18.7301 19.8727 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V16.4934M4.04409 18.2801C4 17.9221 4 17.4575 4 16.8V7.2C4 6.0799 4 5.51984 4.21799 5.09202C4.40973 4.71569 4.71569 4.40973 5.09202 4.21799C5.51984 4 6.07989 4 7.2 4H16.8C17.9201 4 18.4802 4 18.908 4.21799C19.2843 4.40973 19.5903 4.71569 19.782 5.09202C20 5.51984 20 6.0799 20 7.2V16.4934M17 8.99989C17 10.1045 16.1046 10.9999 15 10.9999C13.8954 10.9999 13 10.1045 13 8.99989C13 7.89532 13.8954 6.99989 15 6.99989C16.1046 6.99989 17 7.89532 17 8.99989Z" stroke="#00283A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                <inline-svg :src="require(`@/assets/img/File-Pdf.svg`)" v-else />
                                            </span>
                                            {{d.file.name}}
                                        </li>
                                    </ul>
                                    <div style="max-width: 300px;">
                                        <!-- :current="otherOpen ? 'other' : payload.type"
                                        @change="changeType" -->

                                        <DropdownAdvanced
                                            :options="selectedTypeOptions"
                                            :current="!selectedTypeOptions.map(item => item.id).includes(d.selectedType) ? 'custom' : d.selectedType"
                                            @change="(e) => {d.selectedType = e.target.value.id}"
                                        />
                                    </div>
                                    <div style="max-width: 300px;" v-if="d.selectedType === 'custom' || !selectedTypeOptions.map(item => item.id).includes(d.selectedType)">
                                        <input type="text" class="input" placeholder="Custom" >
                                    </div>
                                    <div class="data-row__controls">
                                        <a :href="getDocumentLink(d.file)" download="" target="_blank" class="btn btn-transp">{{ $translate('view-document') }}</a>

                                        <button class="btn btn-icon red-color" @click="deleteDocument(d.file.lastModified, d.file.name, d.file.size)">
                                            <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <!-- {{ JSON.stringify(documents) }} -->
                        <label type="button" class="btn btn-w-shadow btn-standart-2 label-btn">
                            <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                            <span>{{ $translate('add-document') }}</span>
                            <input style="display:none" type="file"  accept="image/*,.pdf" multiple @change="addDocument"/>
                        </label>
                    </div>
                    <div id="companies" class="tab-content__item" :class="isActiveTab('companies')">
                        <div class="addition">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">{{ $translate('select-companies') }}*</h2>
                                    <p class="headline__text">{{ $translate('add-other-users-to-your-dispatch') }}</p>
                                </div>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">{{companyData.company_name}}</li>
                                            <li class="data-row__item">
                                                <span class="table-user flex items-center"><img :src="profile_photo" alt="" /> {{companyData.user.fullname}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="editable-list__item" v-for="c in dispatchCompanies" :key="c.id">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">{{c.company_name}}</li>
                                            <li class="data-row__item">
                                                <span class="table-user flex items-center"><img :src="c.profile_photo_src" alt="" />{{c.user.fullname||'-   '}}</span>
                                            </li>
                                        </ul>
                                        <div class="data-row__controls">
                                            <button class="btn btn-icon red-color" @click="deleteFieldItem('companies',c.id)">
                                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <WhitePlusButton @click="addCompaniesOpen">{{ $translate('add-companies') }}</WhitePlusButton>

                        </div>
                    </div>
                    <div id="products" class="tab-content__item" :class="isActiveTab('products')">
                        <div class="addition">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">{{ $translate('dispatch-products') }}*</h2>
                                    <p class="headline__text">Here you can add batches and products to your dispatch</p>
                                </div>
                                <div class="buttons-group add-buttons">
                                    <!-- <button type="button" class="btn btn-w-shadow btn-standart-2">
                                        <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                                        <span>QR Code</span>
                                    </button>
                                    <button type="button" class="btn btn-w-shadow btn-standart-2" data-target="qr-code-settings">
                                        <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                        <span>QR Code Settings</span>
                                    </button> -->
                                    <button @click="downloadCsv" type="button" class="btn btn-w-shadow btn-standart-2">
                                        <inline-svg :src="require(`@/assets/img/document-file-table.svg`)" />
                                        <span>{{ $translate('csv-example') }}</span>
                                    </button>
                                    <button type="button" class="btn btn-w-shadow btn-standart-2" @click="openUploadCsv">
                                        <inline-svg :src="require(`@/assets/img/upload-arrow.svg`)" />
                                        <span>{{ $translate('upload-csv') }}</span>
                                    </button>
                                    <WhitePlusButton @click="addBatchOpen">{{ $translate('add-batch') }}</WhitePlusButton>
                                </div>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item" v-for="b in batches" :key="b.id">
                                    <div class="data-row --inner">
                                        <div class="data-row__spoiler spoiler flex-auto --style-2">
                                            <div class="spoiler__button flex items-center" @click="openOptions">
                                                <inline-svg :src="require(`@/assets/img/Arrow-down.svg`)" />
                                                <div class="spoiler__title flex-auto"><span>{{ $translate('batch') }}:</span>{{b.name}}</div>
                                                <div class="buttons-group add-buttons">
                                                    <!-- <button type="button" class="btn btn-transp" data-target="qr-code" @click.self.prevent="()=>{}">
                                                        <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                                                        <span>QR Code</span>
                                                    </button>
                                                    <button type="button" class="btn btn-transp" data-target="qr-code-settings">
                                                        <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                                        <span>QR Code Settings</span>
                                                    </button> -->
                                                    <button type="button" class="btn btn-transp" @click="addProductsOpen(b.id)">
                                                        <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                                                        <span>{{ $translate('add-products') }}</span>
                                                    </button>
                                                    <button type="button" class="btn btn-transp red-color" @click="deleteBatch(b.id)">
                                                        <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                                        <span>{{ $translate('delete-batch') }}</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="spoiler__body flex flex-col">
                                                <div class="table-wrapper" v-if="fieldKeys(b.id).length>0">
                                                    <table class="table style-2">
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 60%">
                                                                    <label class="module__check">
                                                                        <input type="checkbox" name="privacy_policy" :checked="isCheckedAll[b.id]" @change="toggleAllCheckedInputs(b.id)"/>
                                                                        <span class="check"></span>
                                                                        <span class="text">{{ $translate('product-name') }}</span>
                                                                    </label>
                                                                </th>
                                                                <th>{{ $translate('invoice-number') }}</th>
                                                                <th>{{ $translate('po-number') }}</th>
                                                                <th colspan="2">{{ $translate('quantity&qnit') }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="p in fieldValues(b.id, products)" :key="p.id">
                                                                <td>
                                                                    <label class="module__check">
                                                                        <input type="checkbox" name="privacy_policy" :checked="checkedInputs[b.id]?checkedInputs[b.id][p.id]:false" @change="setCheckedInputs($event,b.id,p.id)"/>
                                                                        <span class="check"></span>
                                                                        <!-- <span class="text">{{p.title}}</span> -->
                                                                        <a :href="`/products/${p.id}`" target="_blank" class="text">{{p.title}}</a>
                                                                    </label>
                                                                </td>
                                                                <td>{{p.invoice_number||'-'}}</td>
                                                                <td>{{p.purchase_order||'-'}}</td>
                                                                <td>{{(p.quantity+p.units)||'-'}}</td>
                                                        
                                                                <td>
                                                                    <button class="btn btn-icon red-color" @click="deleteFieldItem(b.id,p.id)">
                                                                        <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="spoiler__body-actions flex" v-else>
                                                    {{ $translate('no-added-products') }}
                                                </div>

                                                <div class="spoiler__body-actions flex">
                                                    <div class="buttons-group add-buttons flex-auto">
                                                        <!-- <button type="button" class="btn btn-transp" data-target="qr-code">
                                                            <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                                                            <span>QR Code</span>
                                                        </button>
                                                        <button type="button" class="btn btn-transp">
                                                            <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                                            <span>QR Code Settings</span>
                                                        </button> -->
                                                        <button type="button" class="btn btn-transp" @click="addProductsOpen(b.id)">
                                                            <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                                                            <span>{{ $translate('add-products') }}</span>
                                                        </button>
                                                    </div>
                                                    <button type="button" class="btn btn-transp red-color" @click="deleteBatch(b.id)">
                                                        <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                                        <span>{{ $translate('delete-batch') }}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="buttons-group add-buttons">
                                <WhitePlusButton @click="addBatchOpen">{{ $translate('add-batch') }}</WhitePlusButton>
                            </div>
                        </div>
                    </div>
                    <div id="conditions" class="tab-content__item" :class="isActiveTab('conditions')">
                        <div class="addition">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">{{ $translate('add-conditions') }}</h2>
                                    <p class="headline__text">Add smart and business conditions to your dispatch to other users. <br>
                                        Please note that the conditions will be monitored and disclosed to the receiving user. <br>
                                        Smart conditions are connected with IOT devices measurements. <br>
                                        Business conditions refer to commercial conditions that you want to be respected.
                                    </p>
                                </div>
                                <div class="buttons-group add-buttons">
                                    <WhitePlusButton @click="openAddSmartCondition">{{ $translate('add-smart-condition') }}</WhitePlusButton>
                                    <WhitePlusButton @click="openAddBusinessCondition">{{ $translate('add-business-condition') }}</WhitePlusButton>
                                </div>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item" v-for="c in smartConditions" :key="c.id">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">
                                                <span class="data-row__icon">
                                                    <inline-svg :src="require(`@/assets/img/ico-signal.svg`)" />
                                                </span>
                                                {{ $translate('smart-conditions') }}
                                            </li>
                                            <li class="data-row__item">
                                                <span class="data-row__span">{{ $translate('batch') }}: </span>
                                                {{findField(batches,c.select_batch_id,"name")}}

                                            </li>
                                            <li class="data-row__item">
                                                <span class="data-row__span">{{ $translate('device') }}: </span>
                                                {{findField(devices,c.select_device,"name")}}
                                            </li>
                                        </ul>
                                        <div class="data-row__controls">
                                            <button type="button" class="btn btn-icon" @click="editSmartCondition(c.id)">
                                                <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                            </button>
                                            <button type="button" class="btn btn-icon red-color" @click="deleteSmartCondition(c.id)">
                                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                                <li class="editable-list__item" v-for="c in businessConditions" :key="c.id">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">
                                                <span class="data-row__icon">
                                                    <inline-svg :src="require(`@/assets/img/suitcase-portfolio.svg`)" />
                                                </span>
                                                {{ $translate('business-condition') }}
                                            </li>
                                            <li class="data-row__item">
                                                <span class="data-row__span">{{ $translate('Batch') }}: </span>
                                                {{ findField(batches,c.select_batch_id,"name") || $translate('all') }}
                                            </li>
                                        </ul>
                                        <div class="data-row__controls">
                                            <button type="button" class="btn btn-icon" @click="editBusinessCondition(c.id)">
                                                <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                            </button>
                                            <button type="button" class="btn btn-icon red-color" @click="deleteBusinessCondition(c.id)">
                                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </main>
       
        <FooterTabsControl
            previous=1
            @cancelAction="cancelDispatch"
            :confirmName="$translate('create-a-dispatch')"
            @confirm="createDispatch('pending')"
            @swipeTab="changeStep"
            :pageTabs="pageTabs"
            :activeTab="activeTab"
            :processing="processing"
        />
        <!-- confirmName="Create A Dispatch"  -->
       
        <QrCodeSettings v-if="qrCodeSettings" @close="closeQrCodeSettings" />

        <QrCode v-if="qrCode.show" :fullLink="qrCode.fullLink" @close="closeQrCode" type="createDisaptchQrCode"/>

        <CsvExample v-if="csvExample" @close="closeCsvExample" @confirm="confirmCsvExample" />

        <UploadCsv v-if="uploadCsv" @close="closeUploadCsv" @submit="parseUploadCSV" />

        <AddSmartCondition 
            v-if="addSmartCondition.show" 
            :condition="addSmartCondition.condition"
            @submit="submitAddSmartCondition" 
            @close="closeAddSmartCondition"
            :batches="batches"
            :devices="devices"
        />

        <AddBusinessCondition 
            v-if="addBusinessCondition.show" 
            :condition="addBusinessCondition.condition"
            @submit="submitAddBusinessCondition" 
            @close="closeAddBusinessCondition"
            :batches="batches"
        />
        
        <AddBatch
            v-if="addBatchPopupInfo.show"
            @close="addBatchClose"
            @submit="addBatchSubmit"
        />

        <AddProducts
            v-if="addProductsPopupInfo.show"
            :products="addProductsPopupInfo.products"
            @close="addProductsClose"
            @submit="addProductsSubmit"
            :processing="aProcessing"
        />

        <AddItems
            v-if="addCompaniesPopupInfo.show"
            :items="addCompaniesPopupInfo.companies"
            @close="addCompaniesClose"
            @submit="addCompaniesSubmit"
            :processing="aProcessing"
            searchFieldProp="company_name"
        >
            <template v-slot:firstColumn="slotData">
                <span class="text">
                    {{slotData.r.company_name}}
                </span>
            </template>
            <template v-slot:columns="slotData">
                <!-- <li class="data-row__item">
                    {{slotData.r.id}}
                </li> -->
                <li class="data-row__item" style="width: 100%;margin-top: 15px;flex-direction: column;align-items: flex-start;">
                    <div style="margin-bottom: 10px;">Team members:</div>
                    <div class="flex items-center" v-for="(userItem, userItemIndex) in usersListData.filter(itemF => itemF.userCompanyId === slotData.r.id)" :key="userItemIndex" style="margin-right: 10px;">
                        <div class="table-user flex items-center">
                            <img :src="userAvatars.find(itemM => itemM.id === userItem.id).url" v-if="userAvatars.find(itemM => itemM.id === userItem.id)">
                            <img src="@/assets/img/user.png" v-else>
                        </div>
                        {{ userItem.fullname || '' }}
                        {{ userItem.email ? ' | ' + userItem.email : '' }}
                    </div>
                </li>
            </template>
        </AddItems>

    </div>
</template>

<script>
import { API,Storage } from 'aws-amplify';
import {
	createDispatch,
    updateDispatch,
    createCompanyDispatch,
    updateProduct
} from '@/graphql/mutations'
import {
	listCompanies,
    getProduct,
    listUsers,
    getCompany,
    getUser,
} from '@/graphql/queries'



import Base from "@/components/base";
import Popups from "@/components/popups";
import Buttons from "@/components/buttons"
import { mixTabs,mixArrayFields,mixProcessing } from '@/mixins';
import { mixDocuments, mixDispatches } from '@/mixins/dispatch';
import {parseCVSconfig} from "@/config/dispatch"

const selectedTypeOptions = [
    { id: 'sustainability rating', name: 'Sustainability rating' },
    { id: 'audits', name: 'Audits' },
    { id: 'absence of child labour', name: 'Absence of child labour' },
    { id: 'safety certification', name: 'Safety certification' },
    { id: 'non pollution', name: 'Non pollution' },
    { id: 'custom', name: 'Custom' },
];

export default {
    name: "CreateDispatch",
    components: {
        ...Base,
        ...Popups,
        ...Buttons,
    },
    mixins: [mixTabs, mixArrayFields, mixProcessing, mixDocuments, mixDispatches],
    watch:{
        async "arrayFields.companies"(){
            this.dispatchCompanies=await this.getDispatchCompanies()
        }
    },
    data() {
        return {
            dispatchID:null,
            qrCode:{
                show:false,
                fullLink:null,
                dispatch:null,
            },
            // documents:[],
            qrCodeSettings: 1,
            csvExample: false,
            uploadCsv: false,
            companiesSource:null,
            dispatchCompanies:[],
            pageTabs:['info', 'companies', 'products', 'conditions'],
            addBatchPopupInfo:{
                show:false
            },
            batches:[],
            arrayFields:{
                companies:[]
            },
            addProductsPopupInfo:{
                show:false,
                batchID:null,
                products:null,
            },
            isCheckedAll:{

            },
            checkedInputs:{

            },
            addSmartCondition:{
                show:false,
                condition:null
            },
            addBusinessCondition: {
                show:false,
                condition:null
            },
            smartConditions:[],
            businessConditions:[],
            documents:[],
            addCompaniesPopupInfo:{
                show:false,
                companies:null
            },
            companyImages:{},
            usersListData: [],
            userAvatars: [],
            selectedTypeOptions,
        };
    },
    computed: {

        products() {
            return this.$store.state.products || [];
        },
        devices() {
            return this.$store.state.devices || [];
        },
        userData() {
			return this.$store.state.userData || {}
		},
        companyData() {
			return this.$store.state.companyData || {}
		},
        profile_photo() {
			return this.$store.state.profile_photo
		},
      
     
	},

    methods: {
        log(value){
            console.log(value);
        },
        checkRequireFields(){
            let returned = true;

            if(
                (this.activeTab === "info" && !this.dispatchID) || 
                (this.activeTab === "companies" && !this.dispatchCompanies?.length) ||
                (this.activeTab === "products" && !this.batches?.length)
            ){
                this.$toast.open({
                    message: 'Please fill all the required information in your dispatch',
                    type: 'error',
                    position: 'top-right'
                });

                returned = false;
            }

            return returned;
        },
        changeStep(value){
            let go = this.checkRequireFields();
            if(!go){
                return false;
            }

            this.swipeTab(value);
        },
        changeTab(value){
            let go = this.checkRequireFields();
            if(!go){
                return false;
            }

            this.switchTab(value);
        },
        async cancelDispatch(){
            const options={title:'',size:'sm',okLable:"Yes",cancelLabel:"No"};
            await this.$dialogs.confirm('Dispatch not completed. Save your dispatch as draft?', options).then(async res=>{
                console.log('res',res);
                if(res.ok===true){
                    this.createDispatch('draft')
                }
                if(res.ok===false){
                    this.$router.push("/dispatch")
                }
            })
        },
        downloadCsv(){
            var url = `${window.location.origin}/csvExample.csv`

            var element = document.createElement('a');
            element.setAttribute('href', url);
            element.setAttribute('download',"Csv Example");

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },
        async getDispatchCompanies(){
            let requests=this.fieldValues('companies',this.companiesSource).map(async company=>{
                return {
                    ...company,
                    profile_photo_src:company.user.profile_photo?await Storage.get(company.user.profile_photo):require("@/assets/img/user.png")
                }
            })
            return await Promise.all(requests).then(res=>res)
        },
        async addCompaniesOpen(){
            let getedCompany = null;

            if(this.userData.teamID){
                getedCompany = await API.graphql({
                    query: getCompany,
                    variables: {
                        id: this.userData.teamID,
                    },
                }).then((res) => res.data.getCompany);
            }

            this.addCompaniesPopupInfo.companies = this.fieldValuesFree("companies", this.companiesSource.filter(c => {
                if(this.userData.company.team){
                    return this.userData.company.team.includes(c.companyUserId);
                } else if(this.userData.teamID && getedCompany) {
                    return (getedCompany.user.company.team.includes(c.companyUserId) || c.id === getedCompany.id) && c.id !== this.userData.userCompanyId;
                }
            }))

            this.addCompaniesPopupInfo.show = true;
        },
        addCompaniesClose(){
            this.addCompaniesPopupInfo.show=false
            this.addCompaniesPopupInfo.companies=null
        },
        async addCompaniesSubmit(companies){
            this.addFieldItems("companies",companies)
            this.addCompaniesClose()
        },
        addDocument(ev){
            let files = Array.from(ev.target.files);
			if(!ev.target.files.length) return;

            console.log('ev.target.files:', ev.target.files);
            

            // console.log('files:', files);

            files = files.map(element => ({
                file: element, 
                selectedType: this.selectedTypeOptions[0].id
            }))

            this.documents = [...this.documents, ...files]
            console.log("this.documents:", this.documents);
        },
        deleteDocument(lastModified, name, size){
            this.documents.splice(this.documents.findIndex(d=> d.file.lastModified == lastModified && d.file.name == name && d.file.size == size),1)
        },
        async createDispatch(status){
            this.processing=true
            //documents
			const input = {
				dispatchID: this.dispatchID,
                ownerID: this.userData.id,
                status:status,
                documents: null,
                // batches
                batches: this.batches.map(b=>{
                    let newBatch={
                        id:b.id,
                        name:b.name
                    }
                    if(this.fieldKeys(b.id)){
                        newBatch.products=this.fieldKeys(b.id)
                    }
                    return newBatch
                }),
                //conditions
                contract_conditions:[
                    ...(this.smartConditions.map(c=>{
                        let newCondition={
                            ...c
                        }
                        delete newCondition.id
                        return newCondition
                    })),
                    ...(this.businessConditions.map(c=>{
                        let newCondition={
                            ...c
                        }
                        delete newCondition.id
                        return newCondition
                    })),
                ],
                companiesStatus:[
                    {
                        id: this.companyData.id,
                        type: 'outcoming',
                        status: status,
                    },
                    ...(this.fieldKeys('companies').map(companyID=>{
                        return {
                            id: companyID,
                            type: 'incoming',
                            status: status,
                        }
                    }))
                ]
            
			}
			const dispatch = await API.graphql({
                query: createDispatch,
				variables: {
                    input
				}
			}).then(res => res.data.createDispatch)

            console.log('dispatch:', dispatch);

            console.log(input);
            for(let b of input.batches){
                for(let p of b.products){
                    console.log(p);
                    const product = await API.graphql({
                        query: getProduct,
                        variables: {
                            id: p,
                        },
                    }).then((res) => res.data.getProduct);
                    let productDispatches=product.dispatches?product.dispatches:[]
                
                    productDispatches.push(dispatch.id)
                    await API.graphql({
                        query: updateProduct,
                        variables: {
                            input: {
                                id: p,
                                dispatches:productDispatches
                            },
                        },
                    })


                }
            }
            input.batches.forEach(b=>{
                b.pro
            })
            this.fieldKeys("companies").forEach(async companyID=>{
                const companyDispatch = await API.graphql({
                    query: createCompanyDispatch,
                    variables: {
                        input:{
                            dispatchID:dispatch.id,
                            companyID:companyID
                        }
                    }
			    }).then(res => res.data.createCompanyDispatch)
            })
            const companyDispatch = await API.graphql({
                query: createCompanyDispatch,
                variables: {
                    input:{
                        dispatchID:dispatch.id,
                        companyID:this.companyData.id
                    }
                }
            }).then(res => res.data.createCompanyDispatch)
            
            //add documents
            let documents=[]
            console.log("start upload doc");
            let requests = this.documents.map(async d=>{
                let s3File = await Storage.put(`document/${dispatch.id}/${d.file.name}`, d.file);
                // , selectedType: d.selectedType
                return { title:d.file.name, key:s3File.key }
            })
			await Promise.all(requests).then(responses => documents = responses);

            console.log('documents:', documents);
            
           try {
                const dispatchWithDocuments = await API.graphql({
                    query: updateDispatch,
                    variables: {
                        input:{
                            id:dispatch.id,
                            documents: documents.map(item => ({
                                ...item,
                                selectedType: this.documents.find(itemF => itemF.file.name === item.title)?.selectedType || ""
                            }))
                        }
                    }
                }).then(res => res.data.updateDispatch);
                console.log('dispatchWithDocuments:', dispatchWithDocuments);
           } catch (error) {
                console.log('error:', error);
           }

            this.processing=false
            this.refreshData()
            // const options={title:'Dispatch Added',size:'sm',okLable:"To Products"} 
            // await this.$dialogs.alert("", options).then(res=>{
            // })
            // this.$router.push('/dispatch');
            console.log('dispatch created',dispatch);
            this.qrCode.show=true
            this.qrCode.fullLink=`${window.location.origin}/qr-dispatch/${dispatch.id}`
            this.qrCode.dispatch=dispatch
        },

        parseUploadCSV(csv){
                let rows=csv.split(/\r\n|\n/);
                
                rows.forEach((row,index)=>{
                    if (row.length<1){
                        return
                    }
                    let cols=row.split(parseCVSconfig.colsDevider)
                    let batch={}
                    let batchID=index+""+Date.now()  
                    batch.id=batchID         
                    parseCVSconfig.model.batch.forEach(mb=>{
                        batch[mb.fieldName]=cols[mb.position]
                    })
                    this.addBatchSubmit(batch)
                    let productsColumns=cols.slice(parseCVSconfig.model.products.startPosition)
                    productsColumns.forEach(c=>{
                        c=c.trim()
                        if(c.length<1){
                            return
                        }
                        if(this.products.findIndex(p=>p.id===c)!==-1){
			                this.addFieldItems(batchID,[c])
                        }
                        if(parseCVSconfig.searchByName){
                            let product=this.products.find(p=>{
                                let reg= new RegExp(parseCVSconfig.searchByNameReg.start+c+parseCVSconfig.searchByNameReg.end)
                                return p.title.match(reg)
                            })
                            if(product){
			                    this.addFieldItems(batchID,[product.id])
                            }
                        }
                    })
                })
               
        },

        addBatchOpen(){
            this.addBatchPopupInfo.show=true
        },
        addBatchClose(){
            this.addBatchPopupInfo.show=false
        },
        addBatchSubmit(batch){
            this.batches.push(batch)
            this.setFieldItems(batch.id,[])
            this.addBatchClose()
        },
        deleteBatch(id){
            this.batches.splice(this.batches.findIndex(b=>b.id===id) ,1)
            this.checkedInputs[id]=null
        },
        addProductsOpen(batchID){
			this.addProductsPopupInfo.show=true
			this.addProductsPopupInfo.batchID=batchID
			// this.addProductsPopupInfo.products=this.fieldValuesFree(batchID,this.products.filter(p=>p.dispatches?p.dispatches.length==0:true))
			this.addProductsPopupInfo.products=this.fieldValuesFree(batchID,this.products);
        },
        addProductsClose(){
			this.addProductsPopupInfo.show=false
			this.addProductsPopupInfo.batchID=null
			this.addProductsPopupInfo.products=null
        },
        addProductsSubmit(pruducts){
			this.addFieldItems(this.addProductsPopupInfo.batchID,pruducts)
			this.addProductsClose()
        },

        //batch checked
        setCheckedInputs(ev,batchID,id){
            if(!this.checkedInputs[batchID]){
                this.$set(this.checkedInputs,batchID,{})
            }

            let  value=ev.target.checked
            this.$set(this.checkedInputs[batchID],id,value)
            if(!value){
                this.isCheckedAll[batchID]=false
            }else{
                let b=true
                this.fieldValues(batchID,this.products).forEach(p=>{
                    b = b && this.checkedInputs[batchID][p.id] 
                })
                if(b){
                    this.isCheckedAll[batchID]=true
                }
            }
        },
        toggleAllCheckedInputs(batchID){
            if(!this.checkedInputs[batchID]){
                this.$set(this.checkedInputs,batchID,{})
            }
            this.fieldKeys(batchID).forEach(id=>{
                this.$set(this.checkedInputs[batchID],id,!this.isCheckedAll[batchID])
            })
            this.isCheckedAll[batchID]=!this.isCheckedAll[batchID]
        },
        
        openOptions(ev) {            
                if(ev.target.closest(".btn")){
                    return
                }
                let parentElement=ev.target.closest(".spoiler__button")
                parentElement.classList.toggle("active");
                parentElement.nextElementSibling.classList.toggle("active");
        },
        closeQrCodeSettings() {
            this.qrCodeSettings = null;
        },
        async closeQrCode() {
            this.qrCode.show = false;
            this.qrCode.fullLink=null
            const options={title:'',size:'sm',okLable:"Send dispatch",cancelLabel:"Not yet"};
            await this.$dialogs.confirm('Are you ready to send this dispatch?', options).then(async res=>{
                console.log('res',res);
                if(res.ok===true){
                    await this.sendDispatch(this.qrCode.dispatch)
                }
            })
            this.qrCode.dispatch=null
            this.$router.push("/dispatch")
        },
        closeCsvExample() {
            this.csvExample = false;
        },
        confirmCsvExample() {
            console.log("csv");
        },

        openUploadCsv(){
            this.uploadCsv = true;
        },
        closeUploadCsv() {
            this.uploadCsv = false;
        },


        openAddSmartCondition() {
            this.addSmartCondition.show = true;
            this.addSmartCondition.condition=null
        },
        closeAddSmartCondition() {
            this.addSmartCondition.show = false;
            this.addSmartCondition.condition=null
        },
        submitAddSmartCondition(conditionConfig,isEdit) {
            if(isEdit){
                this.smartConditions[this.smartConditions.findIndex(c=>c.id==conditionConfig.id)]=conditionConfig
            }else{
                this.smartConditions.push(conditionConfig)
            }
            this.closeAddSmartCondition();
        },
        deleteSmartCondition(id){
            this.smartConditions.splice(this.smartConditions.findIndex(c=>c.id==id),1)
        },
        editSmartCondition(id){
            this.addSmartCondition.show=true
            this.addSmartCondition.condition=this.smartConditions.find(c=>c.id==id)
        },

        openAddBusinessCondition() {
            this.addBusinessCondition.show = true;
            this.addBusinessCondition.condition=null
        },
        closeAddBusinessCondition() {
            this.addBusinessCondition.show = false;
            this.addBusinessCondition.condition=null
        },
        submitAddBusinessCondition(conditionConfig,isEdit) {
            if(isEdit){
                this.businessConditions[this.businessConditions.findIndex(c=>c.id==conditionConfig.id)]=conditionConfig
            }else{
                this.businessConditions.push(conditionConfig)
            }
            this.closeAddBusinessCondition();
        },
        deleteBusinessCondition(id){
            this.businessConditions.splice(this.businessConditions.findIndex(c=>c.id==id),1)
        },
        editBusinessCondition(id){
            this.addBusinessCondition.show=true
            this.addBusinessCondition.condition=this.businessConditions.find(c=>c.id==id)
        },
        getDocumentLink(document){
            return URL.createObjectURL(document)
        }

    },
    async created(){
        let self = this;
        const companies = await API.graphql({
            query: listCompanies,
            variables: {
                
            }
        }).then(res => res.data.listCompanies.items);

        this.companiesSource = companies;

        this.usersListData = await API.graphql({
			query: listUsers,
		}).then((res) => res.data.listUsers.items);

        (async function(){
            self.userAvatars = [];
            let requests = self.usersListData.map(async item => { 
                if(item.profile_photo){
                    return {
                        id: item.id,
                        url: await Storage.get(item.profile_photo)
                    }
                }
            });

            await Promise.all(requests).then(responses => {
                for (const iterator of responses) {
                    if(iterator && iterator.id){
                        self.userAvatars.push(iterator)
                    }
                }
            });
        })();
    },
};
</script>
<style scoped>
    .label-btn:hover{
        cursor: pointer;;
    }
</style>