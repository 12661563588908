
import { API,Storage } from "aws-amplify";
import { getDispatch,getCompany,getProduct,getDevice } from "@/graphql/queries";
import * as graphqlMutations from "@/graphql/mutations";

export const mixDocuments = {
    methods:{
        async uploadDocuments(files){
            let documents
            let request = files.map
            let { name } = file;
            let s3File = await Storage.put(`document/${name}`, file);
            console.log("upload",s3File);
            return {
                key: s3File.key,
                title: name
            }
        },
        async deleteDocument(document,documents){
            await Storage.remove(document.key);

            return documents.filter(d => d.key !== document.key);
        }
    }
}

export const mixDispatches = {
    data() {
        return {
            
        }
    },

    methods: {
        async changeCompanyStatus(dispatchID, companyID, status){
            console.log('change',dispatchID);
            let dispatch = await API.graphql({
                query: getDispatch,
                variables: {
                    id: dispatchID,
                },
            }).then((res) => res.data.getDispatch);

            let companiesStatus = dispatch.companiesStatus.map(c => {
                delete c.__typename;

                if(c.id === companyID){
                    let newStatus = { ...c };
                    newStatus.status = status;

                    switch (status) {
                        case 'incoming':
                            newStatus.incoming_date = Date.now()+'';
                            break;
                        case 'outgoing':
                            newStatus.incoming_date = Date.now()+'';
                            break;
                        case 'receive':
                            newStatus.receive_date = Date.now()+'';
                            break;
                        case 'completed':
                            newStatus.completed_date ? newStatus.completed_date = Date.now()+'' : null;
                            newStatus.receive_date = Date.now()+'';
                        case 'return':
                            newStatus.return_date = Date.now()+'';
                        case 'delivered':
                            newStatus.delivered_date = Date.now()+'';
                            break;
                        default:
                            break;
                    }

                    Object.entries(newStatus).forEach((item)=>{
                        if(!item[1]){
                            newStatus[item[0]] = "";
                        }
                    })

                    console.log('newStatus:', newStatus);
                    

                    return newStatus
                }
                return c
            })

            // console.log('companiesStatus:', companiesStatus);

            console.log('variables input:', {
                id: dispatchID,
                companiesStatus:companiesStatus,
                status: status
            });

            const result = await API.graphql({
                query: graphqlMutations.updateDispatch,
                variables: {
                    input: {
                        id: dispatchID,
                        companiesStatus:companiesStatus,
                        status: status
                    },
                },
            }).then((res) => res.data.updateDispatch);
            
            console.log('newCompany',result);
        },
        isCreator(dispatch,companyID){
            let company = dispatch.companiesStatus.find(c=>{
                return c.id==companyID
            })
            if(!company){
                return false
            }else{
                return company.type=='outcoming'
            }
        },
        getGlobalDispatchStatus(dispatch){
            // let companyId=this.$store.companyData.id
            // console.log('test',dispatch);
            if(!dispatch.companiesStatus){
                return 'Loading...'
            }
            let ownerStatus=dispatch.companiesStatus.find(s=>s.type==='outcoming')
            if(ownerStatus.status==='pending' || ownerStatus.status==='draft' ){
                return ownerStatus.status
            }
            if( dispatch.companiesStatus.length < 2 ){
                return dispatch.companiesStatus.status
            }else{
                let isAllCompleted=true
                let isAllReturned=true
                let isAllIncoming=true
                dispatch.companiesStatus.forEach(s=>{
                    if(s.type === "incoming"){
                        isAllCompleted = isAllCompleted && (s.status==='completed')
                        isAllReturned = isAllReturned && (s.status==='returned')
                        isAllIncoming = isAllIncoming && (s.status==='incoming')
                    }
                })
                if(isAllCompleted){
                    return 'completed'
                }
                else if(isAllReturned){
                    return 'returned'
                }
                else if(isAllIncoming){
                    // console.log(dispatch, 'outgoing');
                    return 'outgoing'
                }else{
                    return 'other'
                }
            }
        },
        async getDispatch(id){
            let dispatchSource=this.$store.state.dispatches.find(d=>d.id==id)
            
            dispatchSource= await API.graphql({
                query: getDispatch,
                variables: {
                    id: id,
                },
            }).then((res) => res.data.getDispatch);
                
            // if(this.$store.state.products.length<1){
            //     await this.$store.dispatch("GET_PRODUCTS")
            // }
            // let products=this.$store.state.products
            // if(this.$store.state.devices<1){
            //     await this.$store.dispatch("GET_DEVICES")
            // }
            // let devices=this.$store.state.devices
            let batchesRequest = dispatchSource.batches.map(async b=>{
                let newBatch={...b}

                let requests = newBatch.products.map(async batchProductID=>{
                    return await API.graphql({
                        query: getProduct,
                        variables: {
                            id: batchProductID,
                        },
                    }).then((res) => res.data.getProduct);
                })
                await Promise.all(requests).then(res=>newBatch.products=res)
                return newBatch
            })
            await Promise.all(batchesRequest).then(res=>dispatchSource.batches=res)
            if(dispatchSource.contract_conditions){
                // dispatchSource.contract_conditions=dispatchSource.contract_conditions.map(async c=>{
                let requests = dispatchSource.contract_conditions.map(async c=>{
                    c.batch=dispatchSource.batches.find(b=>b.id==c.select_batch_id)
                    console.log(c);
                    if (c.condition_type=="business"){
                        return c
                    }
                    if(c.condition_type=="smart"){
                        c.devices = await API.graphql({
                            query: getDevice,
                            variables: {
                                id: c.select_device,
                            },
                        }).then((res) => res.data.getDevice);
                        console.log(c,c.devices);
                        
                        return c
                    }
                })
                await Promise.all(requests).then(res=>dispatchSource.contract_conditions=res)
            }

            let requestsDocuments = dispatchSource.documents.map(async d=>{
                return {
                    id: d.key,
                    key: d.key,
                    title:d.title,
                    url: await Storage.get(d.key) 
                }
            })
            await Promise.all(requestsDocuments).then(responses => dispatchSource.documents = responses);
            
            let requestsCompanies = dispatchSource.companiesStatus.map(async c => { 
                let company = await API.graphql({
                    query: getCompany,
                    variables: {
                        id: c.id,
                    },
                }).then((res) => res.data.getCompany);
                let companyWithPhoto={
                    ...company,
                    profile_photo_src:company.user.profile_photo?await Storage.get(company.user.profile_photo):require("@/assets/img/user.png")

                }
                return companyWithPhoto
            });
            await Promise.all(requestsCompanies).then(responses => dispatchSource.companies = responses);
            this.dispatch=dispatchSource
        },
        async sendDispatch(dispatch) {
            let payloadDispatch = dispatch?.id ? dispatch : this.dispatch;

            for (let i of payloadDispatch.companiesStatus) {
                try {
                    if (i.type == "outcoming") {
                        await this.changeCompanyStatus(payloadDispatch.id, i.id, "outgoing");
                    }
                    if (i.type == "incoming") {
                        await this.changeCompanyStatus(payloadDispatch.id, i.id, "incoming");
                    }

                    this.$toast.open({
                        message: 'Dispatch sent',
                        type: 'success',
                        position: 'top-right'
                    });
                } catch (error) {
                    this.$toast.open({
                        message: error?.errors[0]?.message,
                        type: 'error',
                        position: 'top-right'
                    });
                }
            }
        },
        async approveDispatch() {
            const options = { title: "Approve Dispatch?", size: "sm", okLabel: "Approve", cancelLabel: "Cancel" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
                    await this.changeCompanyStatus(this.id, this.companyData.id, "approved");
                    // this.$router.push("/dispatch/"+this.id)
                }
            });
        },
        async receiveDispatch(dispatchId,companyId) {
            let payloadDisaptchId = dispatchId || this.id
            let payloadCompanyId = companyId || this.companyData.id
            const options = { title: "Receive Dispatch?", size: "sm", okLabel: "Receive", cancelLabel: "Cancel" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
                    await this.changeCompanyStatus(payloadDisaptchId, payloadCompanyId, "completed");
                    // this.$router.push("/dispatch/"+this.id)
                    let dispatch =   await this.$store.dispatch('GET_DISPATCH',{id:this.id});
                    console.log('receive',dispatch);
                    let products = []
                    for( let batch of dispatch.batches){
                        console.log(dispatch,'batch',batch);
                        for( let batchProductID of batch.products){
                            let sourceProduct = await this.$store.dispatch('GET_PRODUCT',{ id: batchProductID });
                            console.log('sourceProduct',sourceProduct);
                            const product = await API.graphql({
                                query: graphqlMutations.createProduct,
                                variables: { 
                                    input: {
                                        companyProductsId: this.$store.state.companyData.id,
                                        batch_id: sourceProduct.batch_id,
                                        description: sourceProduct.description, 
                                        expiry_date: sourceProduct.expiry_date,
                                        invoice_number: sourceProduct.invoice_number,
                                        manufacturing_date: sourceProduct.manufacturing_date,
                                        purchase_order: sourceProduct.purchase_order,
                                        quantity: sourceProduct.quantity, 
                                        receiveData: sourceProduct.receiveData, 
                                        rfid_number: sourceProduct.rfid_number, 
                                        serial_number: sourceProduct.serial_number,
                                        title: sourceProduct.title, 
                                        units: sourceProduct.units, 
                                        status: 'received', 
                                    } 
                                }
                            }).then(res =>res.data.createProduct);
                            console.log('createProduct',product);
                            const mainProductUpdated = await API.graphql({
                                query: graphqlMutations.updateProduct,
                                variables: {
                                    input: {
                                        id: batchProductID,
                                        status: 'Sold'
                                    },
                                },
                            }).then((res) => res.data.updateProduct);
                        }
                    }
                }
            });
        },
        // async addProduct(){},
        async returnDispatch(dispatchId,companyId) {
            let payloadDisaptchId = dispatchId || this.id
            let payloadCompanyId = companyId || this.companyData.id
            const options = { title: "Return Dispatch?", size: "sm", okLabel: "Return", cancelLabel: "Cancel" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
                    await this.changeCompanyStatus(payloadDisaptchId, payloadCompanyId, "returned");
                    // this.$router.push("/dispatch/"+this.id)
                }
            });
        },
        async cancelAllDispatch() {
            const options = { title: "Cancel Dispatch?", size: "sm", okLabel: "Yes", cancelLabel: "No" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
                    for(let c of this.dispatch.companiesStatus){
                        this.changeCompanyStatus(this.id, c.id, "canceled");
                    }
                    if(this.innerPageMode){
                        this.innerPageMode=null
                        this.switchTab(this.pageTabs[0])
                    }
                    // this.$router.push("/dispatch/"+this.id)
                }
            });
        },
    }
}
